define("discourse/plugins/discourse-countdown/discourse/widgets/countdown", ["discourse/widgets/widget", "virtual-dom", "discourse/models/site"], function (_widget, _virtualDom, _site) {
  "use strict";

  (0, _widget.createWidget)("countdown", {
    tagName: "div.discourse-countdown",
    buildKey: () => "countdown",
    html(attrs) {
      let userCustomValue = '';
      let userDate = '';
      let countdownDate = moment(this.siteSettings.discourse_countdown_date);
      let text = this.siteSettings.discourse_countdown_text;
      const secondaryText = this.siteSettings.discourse_countdown_secondary_text;
      const now = moment();
      if (this.currentUser) {
        // Get the field values by name (field keys are just numeric IDs).
        // Helpful technique found in:
        // https://github.com/discourse/discourse-user-field-prompt/blob/main/javascripts/discourse/initializers/discourse-user-field-prompt.js.es6
        // @TODO: set custom field names in admin UI.
        const fieldUserCustomValue = _site.default.currentProp("user_fields").findBy("name", "Exam");
        const fieldUserDate = _site.default.currentProp("user_fields").findBy("name", "Exam Date");

        // For some reason in this context user_fields is undefined at first.
        const userFields = this.currentUser.user_fields;
        if (userFields) {
          userCustomValue = userFields[fieldUserCustomValue.id];
          userDate = userFields[fieldUserDate.id];
        }
      }

      // If there are valid user field values, configure custom countdown.
      // @TODO: set prefix and suffix text in admin UI.
      const userDateParsed = userDate ? moment(userDate) : null;
      if (userCustomValue && userDate && userDate.match(/^\d{4}-\d{2}-\d{2}$/g) && userDateParsed.isValid() && userDateParsed > now) {
        countdownDate = userDateParsed;
        text = `days until my ${userCustomValue} exam.`;
      }

      // Proceed with output if countdown date is in the future.
      if (countdownDate > now) {
        // https://stackoverflow.com/a/9130040
        const daysRemaining = countdownDate.startOf("day").diff(now.startOf("day"), "days");
        const classArray = this.siteSettings.discourse_countdown_classes.split(" ");
        const classes = `.${classArray.join(".")}`;
        const countdownContent = [(0, _virtualDom.h)("div.days", daysRemaining.toString()), (0, _virtualDom.h)("div.text-wrap", [(0, _virtualDom.h)("div.text", text), (0, _virtualDom.h)("div.secondary-text", secondaryText)])];
        if (this.siteSettings.discourse_countdown_url) {
          return (0, _virtualDom.h)("div.countdown" + classes, [(0, _virtualDom.h)("a.wrap", {
            "href": this.siteSettings.discourse_countdown_url,
            "attributes": {
              "target": this.siteSettings.discourse_countdown_new_window ? "_blank" : "_self",
              "rel": "noopener"
            }
          }, countdownContent)]);
        } else {
          return (0, _virtualDom.h)("div.countdown" + classes, (0, _virtualDom.h)("div.wrap", countdownContent));
        }
      }
    }
  });

  // Countdown is time sensitive, so rerender on page changes.
  (0, _widget.decorateWidget)("countdown:after", helper => {
    helper.widget.appEvents.on("page:changed", () => {
      helper.widget.scheduleRerender();
    });
  });
});
define("discourse/plugins/discourse-countdown/discourse/initializers/discourse-countdown", ["exports", "discourse/lib/plugin-api", "discourse/widgets/widget"], function (_exports, _pluginApi, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-countdown",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.13.0", api => {
        const siteSettings = api.container.lookup("site-settings:main");
        if (!siteSettings.discourse_countdown_date) {
          return;
        }
        (0, _widget.decorateWidget)("header-buttons:before", helper => {
          return helper.attach("countdown");
        });
      });
    }
  };
});